@use '@/styles/utils/mixins.scss' as *;

.variantWrapper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 1;
        @include tab() {
          font-size: 48px;
          line-height: 1;
        }
        @include mob() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 1;
        @include tab() {
          font-size: 36px;
          line-height: 1;
        }
      }
      &.titleMd {
        font-size: 20px;
        line-height: 1;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
  .themeLink {
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 600;
    color: $kiaMidnightBlack;
    text-transform: capitalize;
    display: inline-block;
    position: relative;
    &:hover {
      color: $kiaMidnightBlack;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      width: 0;
      background-color: $kiaMidnightBlack;
      transition: width 0.3s;
    }
    &:hover::before,
    &:focus::before,
    &:active::before {
      width: 100%;
      transition: width 0.3s;
    }
    .menuIcon {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: auto;
      right: -20px;
      z-index: 1;
      margin-top: -10px;
      transition: all ease-in-out 0.3s;
    }
    &:hover {
      text-decoration: none !important;
    }
  }

  .themeLinkWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .linkContainer {
      flex: 1 1 calc(33.33% - 8px);

      &:nth-child(n + 4) {
        flex: 1 1 calc(50% - 8px);
      }
    }

    @media (max-width: 768px) {
      .linkContainer {
        flex: 1 1 100%;
      }
    }
  }

  .variantTypeContainer {
    table {
      margin-top: 10px;
      margin-bottom: 40px;
      font-variant-numeric: lining-nums tabular-nums;
      width: 100% !important;
      height: auto !important;
      position: relative;
      border: 1px solid #9ba1a5;
      border-collapse: separate !important;
      border-spacing: 0;
      table-layout: auto;
      overflow-x: scroll;
      @media screen and (max-width: 1024px) {
        display: block;
      }

      col {
        width: auto !important;
        @include tab() {
          width: inherit !important;
        }
      }
      tr {
        height: auto !important;
        @include tab() {
          & > th:first-child,
          & > td:first-child {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;
            background-color: $kiaPolarWhite;
            white-space: normal;
          }
        }
      }
      th,
      td {
        padding: 14px 14px;
        color: $kiaBattleshipGrey;
        border: 1px solid $kiaCoolGrey;
        font-weight: 700;
        font-size: 12px;
        line-height: 1.1;
      }
      th {
        text-transform: capitalize;
        color: $kiaBattleshipGrey;
      }
      td a {
        font-weight: 700;
        font-size: 12px;
      }
      tr:not(:first-child) td:not(:first-child) {
        white-space: nowrap;
      }
      tr td:first-child {
        min-width: 100px;
      }
    }
    p {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    a {
      text-decoration: underline;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
